import React from 'react';
import { nanoid } from 'nanoid';
import { Form, Field } from 'react-final-form';
import { Box, Flex, VStack, SimpleGrid, Spinner, Heading, Text ,Input,  Button,  useDisclosure, Show, Hide,
        Grid, GridItem, Divider, useToast, MenuItem, Menu, MenuButton, MenuList, useBreakpointValue 
} from '@chakra-ui/react';
import { WrappedPage } from 'src/PageComponents/WrappedPage';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { PageHeader } from '../components/PageHeader';
import { useMembershipDetails } from '../api/getMembershipDetails';
import { useTreatmentPlanDetails } from '../api/getTreatmentPlanDetails';
import { TreatmentCodeSearchLocalSearch } from '../components/TreatmentCodeSearch';
import { cloneDeep, isEmpty, isString, omit } from 'lodash';
import { convertToAPIValues, isFinalFormMetaInvalid } from 'src/_libs/forms';
import { useAppConfig } from 'src/App/state/appConfig';
import { useArchiveTreatmentPlan, useCancelTreatmentPlan, useCompleteTreatmentPlan, useRejectTreatmentPlan, useStartTreatmentPlan, useUpdateTreatmentPlan } from '../api/updateTreatmentPlan';
import { PlusIcon } from 'src/_images/icons/AddIcon';
//import ArchiveIcon from 'src/_images/icons/ArchiveIcon';
//import CheckIcon from 'src/_images/icons/CheckIcon';
//import {CloseIcon} from 'src/_images/icons/CloseIcon';
import ThreeVerticalDotsIcon from 'src/_images/icons/ThreeVerticalDotsIcon';

import { numberToFixedDecimal } from '../utils';
import ArrowBackIcon from 'src/_images/icons/ArrowBackIcon';
import { ConfirmationDialog } from '../components/ConfirmationDialog';
import { FeeScheduleListDrawer } from '../components/FeeScheduleList';
import { InfoIcon } from 'src/_images/icons/InfoIcon';
//import ArrowForwardIcon from 'src/_images/icons/ArrowForwardIcon';

const GRID_TEMPLATE_COLUMNS = ["1fr 6fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr"];
const GRID_GAP = '20px';
const GRID_PROPS = {
    px: ['15px', '10px'],
    borderBottom: '1px solid #E2E8F0',
    py:['30px', '10px']
}

const INPUT_BORDER_RADIUS = '5px';


const formatDate = (date) => {
    if (!date) return '';
    return new Intl.DateTimeFormat(navigator.language, {
        year: "numeric",
        month: "short",
        day: "numeric"
    }).format(new Date(date));
};

const StatusTag = ({status}) => {
    const { formatMessage } = useIntl();
    const statusText = {
        'quoted': formatMessage(
            {
                id: 'membership.status.quoted',
                defaultMessage: 'Quoted'
            }
        ),
        'future': formatMessage(
            {
                id: 'membership.status.future',
                defaultMessage: 'Future'
            }
        ),
        'active': formatMessage(
            {
                id: 'membership.status.active',
                defaultMessage: 'Active'
            }
        ),
        'canceled': formatMessage(
            {
                id: 'membership.status.canceled',
                defaultMessage: 'Canceled'
            }
        ),
        'expired': formatMessage(
            {
                id: 'membership.status.expired',
                defaultMessage: 'Expired'
            }
        ),
    }
    // eslint-disable-next-line no-unused-vars
    const boxProps = {
        'quoted': {
            bg: '#F5A623',
            color: '#FFF'
        },
        'future': {
            bg: '#44C5A6',
            color: '#FFF'
        },
        'active': {
            bg: '#44C5A6',
            color: '#FFF'
        },
        'canceled': {
            bg: '#D0021B',
            color: '#FFF'
        },
        'expired': {
            bg: '#D0021B',
            color: '#FFF'
        }
    }
    return (
        <Text>
            {statusText[status]}
        </Text>
    )
}

const VSTACK_PROPS = {
    spacing: '5px',
    align: 'flex-start',
    mt: [0, '10px']
}

const MembershipDetails = ({membershipId}) => {
    const LABEL_TEXT_PROPS = {
        color: '#9B9B9B',
        mr: "4px"
    }
    const { data, isLoading, isFetching } = useMembershipDetails({id: membershipId});

    if (isLoading || isFetching) {
        return (
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    }

    if (data?.membership === null) {
        return (
            <Box>
                <FormattedMessage
                    id="membershipDetails.noneFound"
                    defaultMessage="No Membership Found"
                />
            </Box>
        )
    }

    return (
        <Box pl={[0, 10]} pt={[10, 0]}>
            <Heading 
                size={'md'}
                color={'#2E3D44'}
                textAlign={'left'}
                pb={['15px', '10px']}
            >
                <FormattedMessage 
                    id={'treatmentPlanPage.membershipDetails.title'}
                    defaultMessage='Membership Details'
                />
            </Heading>
            <VStack {...VSTACK_PROPS}>
                <Flex align={'center'}>
                    <Text {...LABEL_TEXT_PROPS}>
                        <FormattedMessage
                            id="treatmentPlanPage.membershipDetails.label.status"
                            defaultMessage='Status'
                        />
                    </Text>
                    <Text {...LABEL_TEXT_PROPS}>
                        {": "}
                    </Text>
                    <StatusTag
                        status={data?.membership?.status}
                    />  
                </Flex>
                <Flex>
                    <Text {...LABEL_TEXT_PROPS}>
                        <FormattedMessage
                            id="treatmentPlanPage.membershipDetails.label.id"
                            defaultMessage='ID'
                        />
                    </Text>
                    <Text {...LABEL_TEXT_PROPS}>
                        {": "}
                    </Text>
                    <Text>
                        {data?.membership?.membership_number}
                    </Text>
                </Flex>
                <Flex>
                    <Text {...LABEL_TEXT_PROPS}>
                        <FormattedMessage
                            id="treatmentPlanPage.membershipDetails.label.plan_name"
                            defaultMessage='Plan Name'
                        />
                    </Text>
                    <Text {...LABEL_TEXT_PROPS}>
                        {": "}
                    </Text>
                    <Text>
                        {data?.membership?.plan_name}
                    </Text>
                </Flex>
                <Flex>
                    <Text {...LABEL_TEXT_PROPS}>
                        <FormattedMessage
                            id="treatmentPlanPage.membershipDetails.label.maximum_benefit"
                            defaultMessage='Max. Benefit'
                        />
                    </Text>
                    <Text {...LABEL_TEXT_PROPS}>
                        {": "}
                    </Text>
                    
                    <Text>
                        {data?.membership?.maximum_benefit ? (
                            <FormattedMessage
                            id="format.currency"
                            defaultMessage="{currency}{amount}"
                            values={{ currency: data?.membership?.currency_symbol, amount: data?.membership?.maximum_benefit }}
                            />
                        ) : (
                            <FormattedMessage
                                id="membershipDetails.noMaximumBenefit.label"
                                defaultMessage="None"
                            />
                        )}
                    </Text>
                </Flex>
            </VStack>
        </Box>
    )
}

const PatientDetails = ({patient}) => {
    const { formatMessage } = useIntl();
    const LABEL_TEXT_PROPS = {
        color: '#9B9B9B',
        mr: "4px"
    }

    return (
        <Box >
            <Heading 
                size={'md'}
                color={'#2E3D44'}
                textAlign={'left'}
                pb={'10px'}
            >
                <FormattedMessage 
                    id={'treatmentPlanPage.patientDetails.title'}
                    defaultMessage='Patient Details'
                />
            </Heading> 
            <SimpleGrid columns={[1,2]} gap={'30px'}>
                <VStack {...VSTACK_PROPS}>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="treatmentPlanPage.patientDetails.label.fullName"
                                defaultMessage='Name'
                            />
                        </Text>
                        <Text {...LABEL_TEXT_PROPS}>
                            {": "}
                        </Text>
                        <Text>
                            <FormattedMessage
                                id="format.fullName"
                                defaultMessage="{givenName} {familyName}"
                                values={{ givenName: patient?.first_name, familyName: patient?.last_name }}
                            />
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="treatmentPlanPage.patientDetails.label.dateOfBirth"
                                defaultMessage='Date of Birth'
                            />
                        </Text>
                        <Text {...LABEL_TEXT_PROPS}>
                            {": "}
                        </Text>
                        <Text>
                            {patient?.date_of_birth ? formatDate(patient.date_of_birth) : '-'}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="treatmentPlanPage.patientDetails.label.gender"
                                defaultMessage='Gender'
                            />
                        </Text>
                        <Text {...LABEL_TEXT_PROPS}>
                            {": "}
                        </Text>
                        <Text>
                            {patient?.gender}
                        </Text>
                    </Flex>
                </VStack>
                <VStack 
                    {...VSTACK_PROPS} 
                    borderBottom={['0.8px solid #C7D3D9', 'none']}
                    borderRight={['none', '0.8px solid #C7D3D9']}
                    pb={[6, 0]}
                    mt={[0, '10px']}
                >
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="treatmentPlanPage.patientDetails.label.account_type"
                                defaultMessage='Account Type'
                            />
                        </Text>
                        <Text {...LABEL_TEXT_PROPS}>
                            {": "}
                        </Text>
                        <Text>
                            {patient.parent != null
                                ? formatMessage({id: 'treatmentPlanPage.patientDetails.label.accountType.dependent', defaultMessage: 'Dependent'}) 
                                : formatMessage({id: 'treatmentPlanPage.patientDetails.label.accountType.primary', defaultMessage: 'Primary'})}
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="treatmentPlanPage.patientDetails.label.linkedTo"
                                defaultMessage='Linked To'
                            />
                        </Text>
                        <Text {...LABEL_TEXT_PROPS}>
                            {": "}
                        </Text>
                        <Text>
                            <FormattedMessage
                                id="format.fullName"
                                defaultMessage="{givenName} {familyName}"
                                values={{ givenName: patient?.parent?.first_name, familyName: patient?.parent?.last_name }}
                            />
                        </Text>
                    </Flex>
                    <Flex>
                        <Text {...LABEL_TEXT_PROPS}>
                            <FormattedMessage
                                id="treatmentPlanPage.patientDetails.label.relationship"
                                defaultMessage='Relationship'
                            />
                        </Text>
                        <Text {...LABEL_TEXT_PROPS}>
                            {": "}
                        </Text>
                        <Text>
                            {patient?.relationship}
                        </Text>
                    </Flex>
                </VStack>
            </SimpleGrid>

        </Box>
    )
}

const PatientMember = ({membership, patient}) => {
    return (
        <Box
            px={[10, 12]}
            py={[8, 10]}
            border={'1px solid rgba(199, 211, 217, 0.40)'}
            borderRadius={'20px'}
        >
            <SimpleGrid templateColumns={["1fr", "3fr 2fr"]}>
                <PatientDetails patient={patient} />
                <MembershipDetails membershipId={membership} />

            </SimpleGrid>
        </Box>
    )


}

const TreatmentItemHeader = () => {
    const TEXT_PROPS = {
        color: '#9B9B9B',
        
    }
    return (

        <Box >
            <Grid {...GRID_PROPS} templateColumns={GRID_TEMPLATE_COLUMNS} gap={GRID_GAP} >
                <GridItem>
                    <Text {...TEXT_PROPS}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.code'}
                            defaultMessage="Treatment Code"
                        />
                    </Text>
                </GridItem>
                <GridItem>
                    <Text {...TEXT_PROPS}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.averagePrice'}
                            defaultMessage="Average Price"
                        />
                    </Text>
                </GridItem>
                <GridItem>
                    <Text {...TEXT_PROPS}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.discount'}
                            defaultMessage="Discount"
                        />
                    </Text>
                </GridItem>
                <GridItem>
                    <Text {...TEXT_PROPS}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.basePrice'}
                            defaultMessage="Our Price"
                        />
                    </Text>
                </GridItem>
                <GridItem>
                    <Text {...TEXT_PROPS}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.quantity'}
                            defaultMessage="Quantity"
                        />
                    </Text>
                </GridItem>
                <GridItem>
                    <Text {...TEXT_PROPS}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.totalCost'}
                            defaultMessage="Total Cost"
                        />
                    </Text>
                </GridItem>
                <GridItem isTruncated>
                    <Text {...TEXT_PROPS} >
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentItem.header.treatmentArea'}
                            defaultMessage="Area"
                        />
                    </Text>
                </GridItem>
                <GridItem>
                    <Box />
                </GridItem>
            </Grid>
        </Box>
    )
}

const TreatmentItem = ({index, isUneditable, plan, item, currencySymbol, handleUpdate, handleDelete, handleUndoDelete, isOddRow, openFeeScheduleDrawer}) => {
    const { formatMessage } = useIntl();

    const TEXT_PROPS = {
        color: '#9B9B9B',
        py: '5px'
    }
    const GRID_ITEM_PROPS = {
        textAlign: 'left'
    }

    const getDiscountedString = (item) => {
        if (item){
            let diff = numberToFixedDecimal(item?.fee_schedule?.average_price )- numberToFixedDecimal(item?.cost_amount);
            let discount = Math.round( diff / numberToFixedDecimal(item?.fee_schedule?.average_price) * 100);
            return `- ${currencySymbol}${diff} (${discount}%)`
        } else {
            return ""
        }
    }

    //const getDiscount = (item) => {
    //    // calculate item.average_price vs item.base_price
    //    if (item?.fee_schedule && item?.cost_amount){
    //        return Math.round((item?.fee_schedule?.average_price - item?.cost_amount) / item?.fee_schedule.average_price * 100);
    //    } else {
    //        return null;
    //    }
    //}

    const addCalculatedItemValues = (item) => {
        // TODO additional cost, etc. when we decide to add it
        return {
            total_cost: numberToFixedDecimal(item?.quantity * item?.cost_amount, 2)
        } 
    }

    const handleSubmit = (value) => {
        const newItem = {...item, ...value};

        handleUpdate({
            ...newItem,
            ...addCalculatedItemValues(newItem)
        });
    }

    const handleSelectCode = (value) => {
        // value?.data?.
        handleUpdate({
            ...item,
            fee_schedule: value?.data,
            code: value?.data?.code,
            name: value?.data?.name,
            cost_amount: value?.data?.base_price,
            average_price: value?.data?.average_price,
            quantity: 1,
            total_cost: value?.data?.base_price,
            
        })
        console.log("code selected ", value)
    }

    const IS_DELETED_PROPS = item.isDeleted ? {
        color: '#A0B1BA'
    } : {}

    const itemValue = item?.fee_schedule ? {value: item?.fee_schedule?.id, label: `${item?.fee_schedule?.code} : ${item?.fee_schedule?.name}`, data: item} : null;

    const templateAreas = [
        `"index index index" 
        "codeSearch codeSearch codeSearch" 
         "averagePrice discountedPrice costAmount"
         "quantity treatmentArea treatmentArea"
         "totalCost totalCost totalCost" 
         "actions actions actions"`,

        `"index codeSearch averagePrice discountedPrice costAmount quantity totalCost treatmentArea actions" "1fr 6fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr"`
    ]
    return (
        <Grid 
            {...GRID_PROPS} 
            templateAreas={templateAreas} 
            templateColumns={["1fr 1fr 1fr", "1fr 6fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr"]} 
            py={['10px']}
            rowGap={['10px', 0]} 
            columnGap={['0', GRID_GAP]}
            bg={item.isDeleted ? 'rgba(199, 211, 217, 0.40)' : isOddRow ? 'rgb(237, 242, 247)' : 'transparent'} 
            mt={['10px', 0]}
        >
            <GridItem {...GRID_ITEM_PROPS} gridArea={'index'}>
                <Flex align={['center']} direction={['row']} height={'100%'} >
                    <Text textAlign={['left']}>
                        <Show below={"md"}>
                            <FormattedMessage 
                                id={'treatmentPlanPage.treatmentItem.index'}
                                defaultMessage='Item '
                            />
                        </Show>
                        {index + 1}
                    </Text>

                </Flex>
            </GridItem>

            <GridItem {...GRID_ITEM_PROPS} gridArea={'codeSearch'}>
                    <Flex justify={'flex-start'} align={['flex-start','center']} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Flex>

                                <Text {...TEXT_PROPS}>
                                    <FormattedMessage 
                                        id={'treatmentPlanPage.treatmentItem.header.code'}
                                        defaultMessage="Treatment Code"
                                    />
                                </Text>
                                <Flex ml={['5px']} onClick={openFeeScheduleDrawer} align={['center']}>
                                    <InfoIcon color={'#718096'}/>
                                </Flex>
                            </Flex>
                        </Show>
                        <Box w={['100%', '80%']}>
                            <TreatmentCodeSearchLocalSearch 
                                value={itemValue}
                                plan={plan}
                                onSelect={handleSelectCode}
                                disabled={isUneditable || item.isDeleted}
                                placeholder={formatMessage({id: 'treatmentPlanPage.treatmentItem.searchPlaceholder', defaultMessage: 'Search for a treatment code'})}
                            />
                        </Box>
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'averagePrice'}>
                <Flex justify={'flex-start'} align={['flex-start','center']} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Text {...TEXT_PROPS}>
                                <FormattedMessage 
                                    id={'treatmentPlanPage.treatmentItem.header.averagePrice'}
                                    defaultMessage="Average Price"
                                />
                            </Text>
                        </Show>
                        <Text {...IS_DELETED_PROPS} textDecoration="line-through" color={'#9B9B9B'}>
                            {item?.fee_schedule?.average_price ? `${currencySymbol} ${item?.fee_schedule?.average_price}` : '-'}
                        </Text>
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'discountedPrice'}>
                    <Flex justify={'flex-start'} align={['flex-start','center']} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Text {...TEXT_PROPS}>
                                <FormattedMessage 
                                    id={'treatmentPlanPage.treatmentItem.header.discount'}
                                    defaultMessage="Discount"
                                />
                            </Text>
                        </Show>
                        <Text {...IS_DELETED_PROPS} color={'#E95252'}> {/* E95252 red */}
                            {getDiscountedString(item) ? `${getDiscountedString(item)}` : '-'}
                        </Text>
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'costAmount'}>
                    <Flex justify={'flex-start'} align={'center'} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Text {...TEXT_PROPS}>
                                <FormattedMessage 
                                    id={'treatmentPlanPage.treatmentItem.header.basePrice'}
                                    defaultMessage="Our Price"
                                />
                            </Text>
                        </Show>
                        <Text {...IS_DELETED_PROPS}>
                            {item?.cost_amount ? `${currencySymbol} ${item?.cost_amount}` : '-'}
                        </Text>
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'quantity'}>
                    <Flex justify={'flex-start'} align={['flex-start','center']} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Text {...TEXT_PROPS}>
                                <FormattedMessage 
                                    id={'treatmentPlanPage.treatmentItem.header.quantity'}
                                    defaultMessage="Quantity"
                                />
                            </Text>
                        </Show>
                        <Input 
                            type={'number'} 
                            w={'50%'}
                            borderRadius={INPUT_BORDER_RADIUS} 
                            size={'sm'} 
                            value={item?.quantity}
                            isDisabled={isUneditable || item.isDeleted}
                            onChange={(e) => handleSubmit({quantity: e.target.value})}
                        />
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'totalCost'}>
                    <Flex justify={'flex-start'} align={['flex-start','center']} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Text {...TEXT_PROPS}>
                                <FormattedMessage 
                                    id={'treatmentPlanPage.treatmentItem.header.totalCost'}
                                    defaultMessage="Total Cost"
                                />
                            </Text>
                        </Show>
                        <Text {...IS_DELETED_PROPS}>
                            {item?.total_cost ? `${currencySymbol} ${item?.total_cost}` : '-'}
                        </Text>
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'treatmentArea'}>
                    <Flex justify={'flex-start'} align={['flex-start','center']} height={'100%'} direction={['column', 'row']}>
                        <Show below={"md"}>
                            <Text {...TEXT_PROPS} >
                                <FormattedMessage 
                                    id={'treatmentPlanPage.treatmentItem.header.treatmentArea'}
                                    defaultMessage="Area"
                                />
                            </Text>
                        </Show>
                        <Input 
                            w={['100%', '80%']}
                            borderRadius={INPUT_BORDER_RADIUS} 
                            size={['sm']} 
                            placeholder={formatMessage({id: 'treatmentPlanPage.treatmentItem.treatmentAreaPlaceholder', defaultMessage: 'Tooth, region, etc.'})}
                            value={item?.treatment_area}
                            isDisabled={isUneditable || item.isDeleted}
                            onChange={(e) => handleSubmit({treatment_area: e.target.value})}
                        />
                    </Flex>
            </GridItem>
            <GridItem {...GRID_ITEM_PROPS} gridArea={'actions'}>
                <Flex justify={['flex-end', 'flex-start']} align={['flex-start','center']} height={'100%'} direction={['row']}>
                        {
                            item.isDeleted ? (
                                <Flex direction={['column', 'row']} align={['flex-end', 'center']}>
                                    <Box>
                                        <Button size={'sm'} isDisabled={isUneditable} variant={'outline'} onClick={() => handleUndoDelete(item.id)}>
                                            <FormattedMessage 
                                                id={'adminPage.reportTemplateFormModal.locale.button.undo'}
                                                defaultMessage="Undo"
                                            />
                                        </Button>
                                    </Box>
                                    <Text color={'#ff0000'} fontSize={'sm'} ml={[0, '10px']} mt={['10px', 0]}>
                                        <FormattedMessage
                                            id={'treatmentPlanPage.treatmentItem.saveToDelete'}
                                            defaultMessage="Save treatment plan to fully delete"
                                        />
                                    </Text>
                                </Flex>
                            ) : (
                                <Flex direction={['column', 'row']}>
                                    <Box>
                                        <Button size={'sm'} isDisabled={isUneditable} variant={'deleteOutline'} onClick={() => handleDelete(item.id)}>
                                            <FormattedMessage 
                                                id={'adminPage.reportTemplateFormModal.locale.button.delete'}
                                                defaultMessage="Delete"
                                            />
                                        </Button>
                                    </Box>
                                </Flex>
                            )
                        }
                    </Flex>
            </GridItem>
        </Grid>
    )
}

const TreatmentDetails = ({treatment_plan}) => {
    const LABEL_TEXT_PROPS = {
        color: '#9B9B9B',
        mr: "4px"
    }
    return (
        <Box mt={['40px', '20px']}>
            <Heading
                size={'md'}
                color={'#2E3D44'}
                textAlign={'left'}
                pb={['20px', '10px']}
            >
                <FormattedMessage
                    id="treatmentPlanPage.treatmentPlanDetails.details.title"
                    defaultMessage='Treatment Plan Details'
                />
            </Heading>
            <Divider />
            <Flex mt={'20px'}>
                <Text {...LABEL_TEXT_PROPS}>
                    <FormattedMessage
                        id="treatmentPlanPage.treatmentPlanDetails.label.treatmentPlanId"
                        defaultMessage='Treatment ID'
                    />
                </Text>
                <Text {...LABEL_TEXT_PROPS}>
                    {": "}
                </Text>
                <Text>
                    {treatment_plan?.treatment_uid}
                </Text>
            </Flex>
            <Flex>
                <Text {...LABEL_TEXT_PROPS}>
                    <FormattedMessage
                        id="treatmentPlanPage.treatmentPlanDetails.label.created_date"
                        defaultMessage='Created Date'
                    />
                </Text>
                <Text {...LABEL_TEXT_PROPS}>
                    {": "}
                </Text>
                <Text>
                    {formatDate(treatment_plan?.created_date)}
                </Text>
            </Flex>
            <Flex>
                <Text {...LABEL_TEXT_PROPS}>
                    <FormattedMessage
                        id="treatmentPlanPage.treatmentPlanDetails.label.status"
                        defaultMessage='Status'
                    />
                </Text>
                <Text {...LABEL_TEXT_PROPS}>
                    {": "}
                </Text>
                <Text>
                    {treatment_plan?.status}
                </Text>
            </Flex>
        </Box>
    )

}

const MENU_ITEM_ATTRS = {
    py: '8px',
  }

const ThreeDotMenu = ({status, handleUpdateStatus}) => {
    const menuItems = {
        'archive': (
            <MenuItem 
                key={'archive'}
                {...MENU_ITEM_ATTRS} 
                color={'#3E5159'}  
                onClick={() => handleUpdateStatus('archive')}
                //icon={<ArchiveIcon />}
            >
                <FormattedMessage 
                    id={'treatmentPlanDetails.actions.archive'}
                    defaultMessage={'Archive Treatment Plan'}
                />
            </MenuItem>
        ),
        'complete': (
            <MenuItem 
                key={'complete'}
                {...MENU_ITEM_ATTRS} 
                color={'#3E5159'}  
                onClick={() => handleUpdateStatus('complete')}
                //icon={<CheckIcon />}
            >
                <FormattedMessage 
                    id={'treatmentPlanDetails.actions.complete'}
                    defaultMessage={'Mark as Completed'}
                />
            </MenuItem>
        ),
        'reject': (
            <MenuItem 
                key={'reject'}
                {...MENU_ITEM_ATTRS} 
                color={'#3E5159'}   
                onClick={() => handleUpdateStatus('reject')}
                //icon={<CloseIcon />}
            >
                <FormattedMessage 
                    id={'treatmentPlanDetails.actions.reject'}
                    defaultMessage={'Mark as Rejected'}
                />
            </MenuItem>
        ),
        'cancel': (
            <MenuItem 
                key={'cancel'}
                {...MENU_ITEM_ATTRS} 
                color={'#3E5159'}  
                onClick={() => handleUpdateStatus('cancel')}
                //icon={<CloseIcon />}
                
            >
                <FormattedMessage 
                    id={'treatmentPlanDetails.actions.cancel'}
                    defaultMessage={'Mark as Canceled'}
                />
            </MenuItem>
        ),
        'start': (
            <MenuItem 
                key={'start'}
                {...MENU_ITEM_ATTRS} 
                color={'#3E5159'}  
                onClick={() => handleUpdateStatus('start')}
                //icon={<ArrowForwardIcon />}
            >
                <FormattedMessage 
                    id={'treatmentPlanDetails.actions.start'}
                    defaultMessage={'Mark as In Progress'}
                />
            </MenuItem>
        )
    }

    const menuItemsByStatus = {
        planned: ['start', 'complete', 'reject', 'cancel'],
        in_progress: ['cancel', 'complete', 'archive'],
        completed: ['archive'],
        rejected: ['archive'],
        canceled: ['archive'],
    }
    if (!menuItemsByStatus[status]) {
        return <></>
    }
    return (
        <Flex onClick={(event) => event.stopPropagation()}>
          <Menu>
            <MenuButton p={'8px'} type={'button'}>
              <ThreeVerticalDotsIcon />
            </MenuButton>
            <MenuList>
                {
                    menuItemsByStatus[status].map((item) => {
                        return menuItems[item]
                    })
                }
            </MenuList>
          </Menu>
        </Flex>
    )
}

const TreatmentList = ({membershipId, currencySymbol, inputValue, onChange, isUneditable, meta}) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { data, isLoading, isFetching } = useMembershipDetails({id: membershipId});

    const getSubTotal = (treatments) => {
        return treatments.reduce((acc, item) => {
            return acc + (numberToFixedDecimal(item.quantity, 2) * numberToFixedDecimal(item?.fee_schedule?.average_price));
        }, 0);
    }

    const getTotalCost = (treatments) => {
        return treatments.reduce((acc, item) => {
            return acc + (numberToFixedDecimal(item.quantity, 2) * numberToFixedDecimal(item?.cost_amount));
        }, 0);
    }

    const getDiscount = (subtotal, totalCost) => {
        let diff = subtotal - totalCost;
        let discount = Math.round( diff / subtotal * 100);
        return `${numberToFixedDecimal(discount,0)}%`
    }

    const handleAdd = () => {
        // just add a blank item to the input value
        onChange(
            [...inputValue, {id: `TEMP_${nanoid()}`, isNew: true}]
        )
    }

    const handleDelete = (id) => {
        onChange(
            inputValue
                .filter(item => !(item.id === id && item.isNew)) // Remove the item if it's new
                .map((item) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            isDeleted: true  // Mark as deleted if not new
                        }

                    }
                    return item;
                }
        ))
    }

    const handleUndoDelete = (id) => {
        onChange(
            inputValue.map((item) => {
                if (item.id === id) {
                    return omit(item, ['isDeleted']);
                }
                return item;
            }
        ))
    }

    const handleUpdate = (newData) => {
        console.log("handleUpdateLocale newData ", newData)
        onChange(
            inputValue.map((item) => {
                if (item.id === newData?.id) {
                    return {
                        ...item,
                        ...newData
                    }
                }
                return item;
            }
        ))
    }

    if (isLoading || isFetching) {
        return (
            <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                <Spinner 
                    size='xl'
                    thickness='4px'
                    speed='0.65s' 
                    color='#44C5A6'
                />
            </Flex>
        )
    }

    const SUMMARY_PROPS = {
        GRID: {columns: [2], gap: '20px', p:'10px', px: ['30px']},
        LABEL: {color: '#9B9B9B', fontSize: '18px', textAlign: 'left'},
        TEXT: { fontWeight: 700, textAlign: 'right', fontSize: '18px'},
    }

    const isEmpty = !Array.isArray(inputValue) || inputValue.length === 0;

    return (
        <Box mt={['40px', '20px']}>
            <FeeScheduleListDrawer 
                isOpen={isOpen}
                onClose={onClose}
                planName={data?.membership?.plan_name}
                plan={data?.membership?.plan_code}
                currencySymbol = {currencySymbol}
            />
            <Flex justify={'space-between'}>
                <Heading
                    size={'md'}
                    color={'#2E3D44'}
                    textAlign={'left'}
                    pb={['20px', '10px']}

                >
                    <FormattedMessage
                        id="treatmentPlanPage.treatmentPlanDetails.treatments.title"
                        defaultMessage='Treatment Items'
                    />
                    
                </Heading>
                <Box textAlign={['left']}>
                    <Button ml={'15px'} variant={'link'} onClick={onOpen} rightIcon={<InfoIcon color={'#718096'} />}>
                        <FormattedMessage 
                            id={'treatmentPlanPage.treatmentPlanDetails.treatments.button.showTreatements'}
                            defaultMessage='View List of All Prices'
                        />
                    </Button>
                </Box>
            </Flex>
            <Divider />
            {
                isEmpty ? (
                    <Box>
                        <Flex pl={'20px'} mt={'20px'}>
                            <Text color={'#6B8A99'}>
                                <FormattedMessage
                                    id="treatmentPlanPage.treatmentPlanDetails.treatments.empty"
                                    defaultMessage='No Treatment Items'
                                />
                            </Text>
                        </Flex>
                    </Box>

                ) : (
                    <Box>
                        <Hide below="md">
                            <TreatmentItemHeader />
                            <Divider />
                        </Hide>
                        <Box w={'full'} align={'center'}>
                            <Box pb={'30px'}>
                                {
                                    (!Array.isArray(inputValue) || inputValue.length === 0) ? (
                                        <Flex pl={'20px'} mt={'20px'}>
                                            <Text color={'#6B8A99'}>
                                                <FormattedMessage
                                                    id="treatmentPlanPage.treatmentPlanDetails.treatments.empty"
                                                    defaultMessage='No Treatment Items'
                                                />
                                            </Text>
                                        </Flex>
                                    ) : (
                                        <>
                                            {(inputValue || []).map((item, idx) => (
                                                <TreatmentItem 
                                                    key={item.id} 
                                                    index={idx}
                                                    isOddRow={idx % 2 === 0}
                                                    isUneditable={isUneditable}
                                                    plan={data?.membership?.plan_code} 
                                                    currencySymbol={currencySymbol}
                                                    item={item} 
                                                    handleUpdate={handleUpdate}    
                                                    handleDelete = {handleDelete}
                                                    handleUndoDelete={handleUndoDelete}
                                                    openFeeScheduleDrawer={onOpen}
                                                />
                                            ))}
                                        </>
                                    )

                                }
                                
                            </Box>
                            {isFinalFormMetaInvalid(meta) && <Text color={'red'}>{meta.error}</Text>}
                        </Box>
                    </Box>
                )
            }
                <Box mt={'20px'}>
                    <Flex justify={['center', 'flex-start']}>
                        <Button isDisabled={isUneditable} variant={'outline'} onClick={handleAdd} leftIcon={<PlusIcon width={10} height={10} /> }>
                            <FormattedMessage
                                id="treatmentPlanPage.treatmentPlanDetails.treatments.button.add"
                                defaultMessage='Add New Treatment Item'
                            />
                        </Button>
                    </Flex>
                </Box>
                <Box>
                    <Flex w={'full'} justify={['center', 'flex-end']} mt={['40px', '20px']} >
                        <Box w={['full', 'auto']}>
                            <Divider />
                            <SimpleGrid {...SUMMARY_PROPS.GRID}>
                                <Text {...SUMMARY_PROPS.LABEL}>
                                    <FormattedMessage
                                        id="treatmentPlanPage.treatmentPlanDetails.treatments.sub_total"
                                        defaultMessage='Sub Total'
                                    />
                                </Text>
                                <Text {...SUMMARY_PROPS.TEXT}>
                                    {((inputValue || []).length > 0) ? `${currencySymbol}${numberToFixedDecimal(getSubTotal(inputValue))}` : '-'}
                                </Text>
                            </SimpleGrid>
                            <SimpleGrid {...SUMMARY_PROPS.GRID}>
                                <Text {...SUMMARY_PROPS.LABEL}>
                                    <FormattedMessage
                                        id="treatmentPlanPage.treatmentPlanDetails.treatments.discount_total"
                                        defaultMessage='Total Discount'
                                    />
                                </Text>
                                <Text {...SUMMARY_PROPS.TEXT} color={'#E95252'}>
                                    {((inputValue || []).length > 0) ? `- ${currencySymbol}${numberToFixedDecimal(getSubTotal(inputValue)-getTotalCost(inputValue))}` : '-'}
                                </Text>
                            </SimpleGrid>
                            <Divider />
                            <SimpleGrid {...SUMMARY_PROPS.GRID}>
                                <Text {...SUMMARY_PROPS.LABEL}>
                                    <FormattedMessage
                                        id="treatmentPlanPage.treatmentPlanDetails.treatments.discount"
                                        defaultMessage='Discount %'
                                    />
                                </Text>
                                <Text {...SUMMARY_PROPS.TEXT} color={'#E95252'}>
                                    {((inputValue || []).length > 0) ? `${getDiscount(getSubTotal(inputValue), getTotalCost(inputValue))}` : '-'}
                                </Text>
                            </SimpleGrid>
                            <Divider />
                            <SimpleGrid {...SUMMARY_PROPS.GRID} bg={'rgba(199, 211, 217, 0.40)'}>
                                <Text {...SUMMARY_PROPS.LABEL}>
                                    <FormattedMessage
                                        id="treatmentPlanPage.treatmentPlanDetails.treatments.sub_total"
                                        defaultMessage='Total'
                                    />
                                </Text> 
                                <Text {...SUMMARY_PROPS.TEXT}>
                                    {((inputValue || []).length > 0) ? `${currencySymbol}${numberToFixedDecimal(getTotalCost(inputValue))}` : '-'}
                                </Text>
                            </SimpleGrid>
                        </Box>
                    </Flex>
                </Box>
        </Box>
    )
}

const ActionButtons = ({submitting, handleBack, isUneditable, handleTreatmentPlanStatusUpdate, status, pristine, treatments}) => {
    const isMobile = useBreakpointValue({ base: true, md: false }); // true for mobile screens, false for larger screens

    return (

        <Box 
        pr={[6, '100px']}
        pl={[6, '50px']}
        py={[3, '20px']}
        position={'fixed'} 
        bottom={['60px', 0]} 
        right={0} 
        left={[0, '90px']}
        bg={'white'}
        zIndex={"sticky"}
        boxShadow= {'0px -1px 6px 0px rgba(200, 201, 211, 0.60)'}
    >
        <Flex  w={'full'}  justify={'space-between'}>
            <Flex align={'center'}>
                <Button 
                    variant={'outline'} 
                    size={['md', 'lg']}
                    isDisabled={submitting}
                    onClick={handleBack}
                    leftIcon={<ArrowBackIcon />}
                >
                    <FormattedMessage
                        id="treatmentPlanPage.treatmentPlanDetails.button.back"
                        defaultMessage='Back'
                    />
                </Button>
            </Flex>
            <Flex justify={'flex-end'} align={'center'}>
                <Hide below="md">
                    <Button 
                        variant={'deleteOutline'} 
                        size={['md', 'lg']}
                        isDisabled={isUneditable || submitting}
                        onClick={() => handleTreatmentPlanStatusUpdate('cancel')}
                    >
                        <FormattedMessage
                            id="treatmentPlanPage.treatmentPlanDetails.button.cancel"
                            defaultMessage='Mark Cancel'
                        />
                    </Button>
                </Hide>
                <Button 
                    type={'submit'} 
                    ml={'20px'} 
                    size={['md', 'lg']}
                    isDisabled={isUneditable || pristine || submitting || isEmpty(treatments)}
                    isLoading={submitting}
                    mr={'10px'}
                >
                    <FormattedMessage
                        id="treatmentPlanPage.treatmentPlanDetails.button.save"
                        defaultMessage='Save Plan'
                    />
                </Button>
                <ThreeDotMenu 
                    isMobile={isMobile}
                    status={status}
                    handleUpdateStatus={handleTreatmentPlanStatusUpdate}
                />
            </Flex>
        </Flex>
    </Box>
    )
}


export const TreatmentPlanPage = () => {
    const history = useHistory();
    const toast = useToast();
    const { formatMessage } = useIntl();
    const { treatment_id } = useParams();
    const clinic = useAppConfig(state => state.clinic?.id);
    const { data, isLoading, isFetching } = useTreatmentPlanDetails({id: treatment_id});
    // eslint-disable-next-line no-unused-vars
    const updateTreatmentPlanMutation = useUpdateTreatmentPlan({id: treatment_id, clinic}); 
    const [confirmType, setConfirmType ] = React.useState(null);
    const cancelMutation = useCancelTreatmentPlan({id: treatment_id, clinic});
    const archiveMutation = useArchiveTreatmentPlan({id: treatment_id, clinic});
    const startMutation = useStartTreatmentPlan({id: treatment_id, clinic});
    const rejectMutation = useRejectTreatmentPlan({id: treatment_id, clinic});
    const completeMutation = useCompleteTreatmentPlan({id: treatment_id, clinic});

    const handleBack = () => {
        history.push('/treatmentPlans');
    }

    const handleTreatmentPlanStatusUpdate = (statusType) => {   
        // open dialog for cancel, archive, start, reject, etc.
        setConfirmType(statusType);
    }

    const handleDialogClose = () => {
        setConfirmType(null);
    }

    const handleDialogSuccess = () => {
        toast({
            title: formatMessage({
                id: 'treatmentPlanPage.treatmentPlanDetails.toast.success.title',
                defaultMessage: 'Treatment Plan Updated'
            }),
            description: formatMessage({
                id: 'treatmentPlanPage.treatmentPlanDetails.toast.success.description',
                defaultMessage: 'Treatment Plan has been updated successfully'
            }),
            status: 'success',
            duration: 2000,
            isClosable: true,
        })
    }


    const handleSubmit = async (values) => {
        // if isNew and isDeleted, remove it
        const valuesCopy = cloneDeep(values);
        const treatments = valuesCopy.treatments.map((item) => {
            if (item.isNew || (item.id && isString(item.id) && item.id.startsWith('TEMP_'))) {
                // eslint-disable-next-line no-unused-vars
                const { id, ...rest } = item;
                return convertToAPIValues(rest);
            } else {
                return convertToAPIValues(item);
            }
        });
        const convertedValues = convertToAPIValues(valuesCopy);
        convertedValues.treatments = treatments
        try {
            // eslint-disable-next-line no-unused-vars
            await updateTreatmentPlanMutation.mutateAsync(convertedValues);
            toast({
                title: formatMessage({
                    id: 'treatmentPlanPage.treatmentPlanDetails.toast.success.title',
                    defaultMessage: 'Treatment Plan Saved'
                }),
                description: formatMessage({
                    id: 'treatmentPlanPage.treatmentPlanDetails.toast.success.description',
                    defaultMessage: 'Treatment Plan has been saved successfully'
                }),
                status: 'success',
                duration: 2000,
                isClosable: true,
            })
        } catch (error) {
            return {
                ...error
            }
        }
        
    }
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); // Prevent the default action
        }
    };

    const isUneditable = ['completed', 'canceled', 'rejected'].includes(data?.treatment_plan?.status);

    return (
        <WrappedPage>
            <ConfirmationDialog 
                confirmType={confirmType}
                isOpen={confirmType !== null}
                onClose={handleDialogClose}
                mutationFunction={confirmType === 'cancel' ? cancelMutation : 
                    confirmType === 'archive' ? archiveMutation : 
                    confirmType === 'start' ? startMutation : 
                    confirmType === 'reject' ? rejectMutation : 
                    confirmType === 'complete' ? completeMutation : null}
                onSuccess={handleDialogSuccess}
            />
            <Box pr={[0, "80px"]} pb={['140px', '150px']}>
                <Box>
                    <PageHeader page="treatmentPlanCreate" />
                </Box>
                {
                    (isLoading || isFetching) ? (
                        <Flex w={['full']} align={'center'} justify={'center'} p={10}>
                            <Spinner 
                                size='xl'
                                thickness='4px'
                                speed='0.65s' 
                                color='#44C5A6'
                            />
                        </Flex>
                    ) : (
                        <>
                            <Box mt={'40px'} maxW={['100%', '1000px']}> 
                                <PatientMember membership={data?.treatment_plan?.membership} patient={data?.treatment_plan?.patient} />
                            </Box>
                            <Box mt={[0, '40px']}>
                                <Form 
                                    onSubmit={handleSubmit}
                                    initialValues={data?.treatment_plan}
                                    mutators={{ 
                                        setValue: ([field, value], state, { changeValue }) => {
                                            changeValue(state, field, () => value)
                                        }
                                    }}
                                    render = {
                                        ({
                                            handleSubmit,
                                            submitting,
                                            values,
                                            pristine
                                        }) => {
                                            return (
                                            <Box as={'form'} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                                                <TreatmentDetails treatment_plan={data?.treatment_plan}/>
                                                <Field name="treatments">
                                                    {({input, meta}) => (
                                                        <TreatmentList 
                                                            isUneditable={isUneditable}
                                                            membershipId={data?.treatment_plan?.membership} 
                                                            currencySymbol={data?.treatment_plan?.currency_symbol}
                                                            inputValue={input?.value}
                                                            onChange={input?.onChange}
                                                            meta={meta} 
                                                        />
                                                    )}
                                                </Field>
                                                <ActionButtons 
                                                    submitting={submitting}
                                                    handleBack={handleBack} 
                                                    isUneditable={isUneditable}
                                                    handleTreatmentPlanStatusUpdate={handleTreatmentPlanStatusUpdate}
                                                    pristine={pristine}
                                                    status={values?.status}
                                                    treatments={values?.treatments}
                                                />
                                            </Box>
                                        )}
                                    }
                                />

                            </Box>
                        </>
                    )
                }
            </Box>
        </WrappedPage>
    )
}