import { useQuery } from 'react-query';
import { queryConfig } from 'src/_libs/react-query';
import { queryClient } from 'src/_libs/react-query';

import { toString } from 'lodash';

import { fetchRecordUploadDetections } from './services';

export const QUERY_KEY_NAME = 'UploadDetections';

const newQueryConfig = {
    ...queryConfig,
    refetchOnMount: false,
    queries: {
        ...queryConfig.queries,
    }
}

export const queryUploadDetections = async ({ recordUploadUid, clinic }) => {
    return fetchRecordUploadDetections(recordUploadUid, clinic);
}

export const updateUploadDetections = async (recordUploadUid, data) => {
    queryClient.setQueryData([QUERY_KEY_NAME, {id: toString(recordUploadUid)}], (oldData) => {
        return {
            ...(oldData || {}),
            ...data
        }
    });
}


export const useUploadDetections = ({ recordUploadUid, clinic, config={} }) => {
    return useQuery({
        ...newQueryConfig,
        ...config,
        refetchOnMount: false,
        enabled: !!recordUploadUid,
        queryKey: [QUERY_KEY_NAME, {id: toString(recordUploadUid)}],
        queryFn: () => queryUploadDetections({recordUploadUid, clinic}),
      });
}